/* eslint-disable @typescript-eslint/require-await */
import { SnackbarSingleton } from '@rfh/ui'
import { FC, ReactElement, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import Routes from 'src/common/components/Routes'
import { ConfirmationProvider } from 'src/common/providers/ConfirmationProvider'
import 'src/i18n'
import 'src/modules/karinfo/styles/select.scss'

import { Layout } from './common/components/Layout/Layout'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Config } from 'src/common/config'
import { SecureRoute } from '@okta/okta-react'
import { LoginCallback } from '@okta/okta-react'

const oktaAuth = new OktaAuth(Config.oidc)

const App: FC = (): ReactElement => {
  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  return (
    <ConfirmationProvider>
      <Suspense fallback={null}>
        <Router>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Layout>
              <SnackbarSingleton />
              <Switch>
                <Route path='/callback' component={LoginCallback} />
                <SecureRoute path='/' />
              </Switch>
              <Routes />
            </Layout>
          </Security>
        </Router>
      </Suspense>
    </ConfirmationProvider>
  )
}
export default App
