import { FC, useState, ReactElement, ChangeEvent } from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Select,
  MenuItem,
} from '@material-ui/core'
import i18n, { supportedLanguages } from '../../i18n'

interface IProps {
  open: boolean
}

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {},
  })

const LanguageSelector: FC<PropsType> = (props: PropsType): ReactElement => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)

  const onChangeLanguage = (event: ChangeEvent<{ value: unknown }>): void => {
    let language: string = event.target.value as string
    language = language.length > 2 ? language.substr(0, 2) : language
    setCurrentLanguage(language)
    i18n.changeLanguage(language)
  }

  return (
    <Select open={props.open} value={currentLanguage} onChange={onChangeLanguage}>
      {supportedLanguages.map(
        (language: string): ReactElement => (
          <MenuItem key={language} value={language}>
            {language}
          </MenuItem>
        )
      )}
    </Select>
  )
}

export default withStyles(styles)(LanguageSelector)
