/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/unbound-method */
import { createContext, FC, ReactElement, useRef, useState } from 'react'
import {
  ConfirmationDialog,
  ConfirmationOptions,
} from '../components/ConfirmationDialog'

export const ConfirmationProviderContext = createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject)

interface IProps {
  children: any
}

type PropsType = IProps

export const ConfirmationProvider: FC<PropsType> = ({ children }): ReactElement => {
  const [options, setOptions] = useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions): Promise<void> => {
    setOptions(options)
    return new Promise<void>((resolve, reject): void => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = (): void => {
    if (options?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setOptions(null)
  }

  const handleSubmit = (): void => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setOptions(null)
  }

  return (
    <>
      <ConfirmationProviderContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationProviderContext.Provider>

      {options && (
        <ConfirmationDialog
          open={Boolean(options)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          {...options}
        />
      )}
    </>
  )
}
