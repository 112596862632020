/* eslint-disable no-shadow */
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { FC } from 'react'
import notFound from 'src/illustrations/pluto-page-not-found.png'
import { ThemeConfig } from 'src/common/config'

export enum IllustrationType {
  notFound = 'notFound',
}

interface IProps {
  type: IllustrationType
}

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {
      width: '100%',
      height: 'auto',
      maxWidth: '40vw',
      marginTop: theme.spacing(ThemeConfig.spacing.large),
      [theme.breakpoints.only('xs')]: {
        marginTop: theme.spacing(ThemeConfig.spacing.small),
        maxWidth: '60vw',
      },
      [theme.breakpoints.only('sm')]: {
        marginTop: theme.spacing(ThemeConfig.spacing.medium),
        maxWidth: '50vw',
      },
      '& img': {
        width: 'inherit',
        height: 'inherit',
        objectFit: 'contain',
      },
    },
  })

const Illustration: FC<PropsType> = (props: PropsType) => {
  const { classes, type } = props
  return (
    <div className={classes.root}>
      {type === IllustrationType.notFound && <img src={notFound} alt={'not found'} />}
    </div>
  )
}

export default withStyles(styles)(Illustration)
