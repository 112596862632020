import * as request from 'superagent'
import {
  isOnline,
  getLocalItem,
  setLocalItem,
  updateLocalItem,
  addOfflineEvent,
  removeLocalItem,
  addLocalItem,
} from '../utils/managers/OfflineManager'
import { IAbbreviation } from 'src/common/interfaces'
import { Config } from 'src/common/config'

const backendUrl = Config.api.host
const abbreviationsRoute = 'Abbreviations'
const localStorageKey = 'abbreviations'

export const getAbbreviations = async (
  accessToken: string
): Promise<IAbbreviation[]> => {
  if (!isOnline()) {
    return getLocalItem<IAbbreviation[]>(localStorageKey)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${abbreviationsRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    setLocalItem<IAbbreviation[]>(localStorageKey, response.body)
    return response.body
  } catch (error) {
    return error.message
  }
}

export const getAbbreviation = async (
  id: string,
  accessToken: string
): Promise<IAbbreviation> => {
  if (!isOnline()) {
    return getLocalItem<IAbbreviation>(localStorageKey, id)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${abbreviationsRoute}/${id}`)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('Content-Type', 'application/json')
    updateLocalItem<IAbbreviation>(localStorageKey, response.body, id)
    return response.body
  } catch (error) {
    return error.message
  }
}

export const removeAbbreviation = async (
  id: string,
  accessToken: string
): Promise<IAbbreviation[]> => {
  if (!isOnline()) {
    addOfflineEvent('removeAbbreviation', id)
    return removeLocalItem<IAbbreviation[]>(localStorageKey, id) as IAbbreviation[]
  }

  try {
    const response = await request
      .del(`${backendUrl}/${abbreviationsRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    removeLocalItem<IAbbreviation[]>(localStorageKey, id)
    return response.body
  } catch (error) {
    return error.message
  }
}

export const addAbbreviation = async (
  item: IAbbreviation,
  accessToken: string
): Promise<IAbbreviation> => {
  item.createdBy = 'ar'
  // Use this dateFormat for the Dev .net Lambda
  item.created = '0001-01-01'
  // You can use this for your local Json Server
  // `${new Date().getFullYear().toString()}` +
  // '/' +
  // `${new Date().getMonth().toString()}` +
  // '/' +
  // `${new Date().getDate().toString()}`

  if (!isOnline()) {
    addOfflineEvent('addAbbreviation', item)
    return new Promise((_, reject): void => reject('no internet connection'))
    // return offlineManager.addItemToLocalStorage<IAbbreviation>(localStorageKey, item)
    // TODO: show toaster that there is no Internet connection and promise to update when it is back
    //       idem by delete and update
  }

  try {
    const response = await request
      .post(`${backendUrl}/${abbreviationsRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    addLocalItem<IAbbreviation>(localStorageKey, response.body)
    return response.body
  } catch (error) {
    return error.message
  }
}

export const updateAbbreviation = async (
  item: Partial<IAbbreviation>,
  accessToken: string
): Promise<IAbbreviation> => {
  if (!isOnline()) {
    addOfflineEvent('updateAbbreviation', item)
    return updateLocalItem<IAbbreviation>(
      localStorageKey,
      item as IAbbreviation,
      item.id
    )
  }

  let putUri = `${backendUrl}/${abbreviationsRoute}`
  if (backendUrl === 'http://localhost:4000') {
    // This is needed for the local json server
    putUri = `${backendUrl}/${abbreviationsRoute}/${item.id}`
  }

  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    updateLocalItem<IAbbreviation>(localStorageKey, response.body, item.id)
    return response.body
  } catch (error) {
    return error.message
  }
}
