import * as request from 'superagent'
import { IKarInfo, IKarInfoSelectie } from '../../common/interfaces'
import { Config } from '../../common/config'
import {
  addLocalItem,
  getLocalItem,
  setLocalItem,
} from '../utils/managers/OfflineManager'
import { errorMonitor } from 'stream'

const backendUrl = Config.api.host
const karinfoRoute = 'KarInfo'
const localStorageKey = 'karinfo'
//const accessToken = 'todoTOKEN'

export const getKarInfos = async (
  accessToken: string,
  item: IKarInfoSelectie
): Promise<IKarInfo[]> => {
  if (!isOnline()) {
    console.log('is not online karinfo')
    console.log(item)
    return getLocalItem<IKarInfo[]>(localStorageKey)
  }

  try {
    const response = await request
      .post(`${backendUrl}/${karinfoRoute}/search`)
      .set('Content-Type', 'application/json')
      //.set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    console.log({ response })
    //setLocalItem<IKarInfo[]>(localStorageKey, response.body)
    return response.body
  } catch (error) {
    console.log(error)
    return error.message
  }
}

function isOnline() {
  return true
}
