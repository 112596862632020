export interface ITheme {
  spacing: {
    xsmall: number
    small: number
    smallmedium: number
    medium: number
    large: number
    xlarge: number
  }
}

export const ThemeConfig: ITheme = {
  spacing: {
    xsmall: 1,
    small: 2,
    smallmedium: 3,
    medium: 4,
    large: 6,
    xlarge: 7.5,
  },
}
