import { FC, ReactElement } from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Container,
  Typography,
} from '@material-ui/core'
import Illustration, { IllustrationType } from './Illustration'
import { ThemeConfig } from 'src/common/config'

interface IProps {
  illustration?: IllustrationType
  title: string
  subtitle?: string
}

type ClassKey = 'root'
type PropsType = IProps & WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(ThemeConfig.spacing.small),
      '& img': {
        marginBottom: theme.spacing(ThemeConfig.spacing.medium),
      },
    },
  })

const EmptyState: FC<PropsType> = (props: PropsType): ReactElement => {
  const { classes, title, subtitle, illustration } = props

  return (
    <Container maxWidth='lg' className={classes.root}>
      {illustration && <Illustration type={illustration} />}
      <Typography variant='h3' gutterBottom={true}>
        {title}
      </Typography>
      {subtitle && <Typography variant='body1'>{subtitle}</Typography>}
    </Container>
  )
}

export default withStyles(styles)(EmptyState)
