import {
  Container,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Button } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { EmptyState, IllustrationType } from 'src/common/components'

type ClassKey = 'root'
type PropsType = WithStyles<ClassKey>

const styles = (_: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })

const NotFound: FC<PropsType> = (props: PropsType): ReactElement => {
  const { classes } = props

  const history = useHistory()
  const { t } = useTranslation()

  const gotoHome = (): void => {
    history.push('/')
  }

  return (
    <Container maxWidth='lg' className={classes.root}>
      <EmptyState
        title={t('notFoundTitle')}
        subtitle={t('notFoundSubtitle')}
        illustration={IllustrationType.notFound}
      />
      <Button variant='contained' color='primary' onClick={gotoHome}>
        {t('gotoHome')}
      </Button>
    </Container>
  )
}

export default withStyles(styles)(NotFound)
