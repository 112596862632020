import { FC, ReactElement } from 'react'

import Skeleton from '@material-ui/lab/Skeleton'

export const TableRowMock: FC = (): ReactElement => (
  <tr>
    <td>
      <Skeleton variant='text' />
    </td>
    <td>
      <Skeleton variant='text' />
    </td>
    <td>
      <Skeleton variant='text' />
    </td>
    <td>
      <Skeleton variant='text' />
    </td>
    <td>
      <Skeleton variant='text' />
    </td>
    <td>
      <Skeleton variant='text' />
    </td>
    <td>
      <Skeleton variant='text' />
    </td>
  </tr>
)
