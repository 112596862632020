import { FlowerIcon } from '@rfh/ui/shared/floriday-icons'

import {
  Card,
  CardActionArea,
  CardHeader,
  Container,
  createStyles,
  Grid,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ThemeConfig } from 'src/common/config'

type ClassKey = 'content' | 'root'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {
      flexGrow: 1,
    },
    content: {
      padding: theme.spacing(ThemeConfig.spacing.small),
      minHeight: 'calc(100vh - 64px - 8px)', // 64px: appbar, 8px: rainbow
      [theme.breakpoints.only('xs')]: {
        minHeight: 'calc(100vh - 64px)',
      },
    },
  })

const HomePage: FC<PropsType> = (props: PropsType): ReactElement => {
  const { classes } = props
  const { t } = useTranslation()
  const history = useHistory()

  const onClickKarInfo = (): void => {
    history.push('/lusinfo')
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='lg' className={classes.content}>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h1'></Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardActionArea onClick={onClickKarInfo}>
                <CardHeader avatar={<FlowerIcon />} title={t('lusinfo')} />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default withStyles(styles)(HomePage)
