import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Button } from '@rfh/ui'
import { FC, ReactElement } from 'react'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant: 'danger' | 'info'
  title: string
  description: string
  okbuttontext: string
  cancelbuttontext: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  okbuttontext,
  cancelbuttontext,
  onSubmit,
  onClose,
}): ReactElement => (
  <Dialog open={open}>
    <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {variant === 'danger' && (
        <>
          <Button color='primary' onClick={onSubmit}>
            {okbuttontext}
          </Button>
          <Button color='primary' onClick={onClose} autoFocus>
            {cancelbuttontext}
          </Button>
        </>
      )}

      {variant === 'info' && (
        <Button color='primary' onClick={onSubmit}>
          {okbuttontext}
        </Button>
      )}
    </DialogActions>
  </Dialog>
)
