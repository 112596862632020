export interface IFilterArgument {
  property: string
  value: string | RegExp
}

const doFilter = (item: any, filter: IFilterArgument): boolean => {
  let { value } = filter
  const { property } = filter

  if (!(value instanceof RegExp)) {
    value = filter.value = new RegExp(value, 'i')
  }

  return value.test(item[property])
}

type curryType = <T>(item: T) => boolean

/**
 * A filter function which returns the items which have a match with all the `filters`.
 * @param filters An array of filters based on `IFilterArgument`
 */
export const createInclusiveFilter =
  (...filters: IFilterArgument[]): curryType =>
  <T>(item: T): boolean =>
    filters.every(filter => doFilter(item, filter))

/**
 * A filter function which returns the items which have at least one match with any of the `filters`
 * @param filters An array of filters based on `IFilterArgument`
 */
export const createExclusiveFilter =
  (...filters: IFilterArgument[]): curryType =>
  <T>(item: T): boolean =>
    filters.some(filter => doFilter(item, filter))
