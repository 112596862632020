import ReactDOM from 'react-dom'
import App from './App'
import { Capacitor } from '@capacitor/core'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// Service worker registration and serviceWorkerNotifications for FCM
if (Capacitor.getPlatform() === 'web') {
  serviceWorkerRegistration.register()
} else {
  serviceWorkerRegistration.unregister()
}

const rootNode = document.getElementById('root')
ReactDOM.render(<App />, rootNode)
