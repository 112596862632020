/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Button, TextField } from '@rfh/ui'
import { publishToTopicAsync } from 'src/common/services/AWSService'
import { v4 as uuid } from 'uuid'
import { PublishMsgToTopicDotnet } from 'src/common/services/AWSService'

type MessageToSend = { body: string; title: string }

export const PushNotifications: React.FC = () => {
  const [message, setMessage] = React.useState<MessageToSend>({
    title: 'Demo Message',
    body: 'Sent at ' + new Date().toLocaleString(),
  })

  const onChange = (e: any) => {
    const nm: Record<string, string> = {}
    nm[e.target.name] = e.target.value
    setMessage({ ...message, ...nm })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    PublishMsgToTopicDotnet(message.body, message.title)
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        <TextField
          name='title'
          label='Title'
          value={message.title}
          fullWidth
          onChange={onChange}
        />
      </div>
      <br />
      <div>
        <TextField
          label='Body'
          multiline
          name='body'
          value={message.body}
          fullWidth
          onChange={onChange}
        />
      </div>
      <br />
      <Button variant='outlined' type='submit'>
        {'Publish message'}
      </Button>
    </form>
  )
}
