import { createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Dialog } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AbbreviationDetailFields } from 'src/common/components/AbbreviationDetailFields'
import { FormMode } from 'src/common/enums'
import { IAbbreviation } from 'src/common/interfaces'
import { addAbbreviation } from 'src/common/services'

type ClassKey = 'root'

const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {
      '& .MuiDialog-paper': {
        backgroundColor: '#ECEBEB', // theme.Rfhcolors doesnt exist anymore,
        maxWidth: '100%',
      },
    },
  })
interface IProps {
  close: (abbrData?: IAbbreviation) => void
  isOpen: boolean
}

type PropsType = IProps & WithStyles<ClassKey>

const NewAbbreviationModal: FC<PropsType> = (props: PropsType): ReactElement => {
  const { close, isOpen } = props
  const methods = useForm()
  const { t } = useTranslation()
  const { authState } = useOktaAuth()

  const onSubmit = async (item?: any): Promise<void> => {
    const addData = async (abbrItem: any) => {
      try {
        await addAbbreviation(abbrItem, authState.accessToken?.accessToken)
      } catch (error) {
        console.log(error)
        close()
      }
    }

    await addData(item)
    close()
  }

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Dialog
          fullScreen={true}
          modalIsOpen={isOpen}
          title={t('abbreviationAddDialogTitle')}
          onClose={(): void => close()}
          actions={
            <Grid container direction={'row'} justify={'center'}>
              <Grid item xs={2}>
                <Button
                  variant='outlined'
                  type='reset'
                  onClick={() => methods.reset()}
                  style={{ width: '100%' }}
                >
                  {t('reset')}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant='contained'
                  type='submit'
                  onClick={methods.handleSubmit(onSubmit)}
                  style={{ width: '100%' }}
                >
                  {t('submit')}
                </Button>
              </Grid>
            </Grid>
          }
        >
          <AbbreviationDetailFields mode={FormMode.add} />
        </Dialog>
      </form>
    </FormContext>
  )
}

export default withStyles(styles)(NewAbbreviationModal)
