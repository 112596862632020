import { FC, ReactElement } from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TableRow,
  IconButton,
} from '@material-ui/core'
import { IKarInfo } from '../../../common/interfaces'

interface IProps {
  karInfo: IKarInfo
  classType: string
}

type ClassKey = 'root' | 'abrcell'
const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {},
    abrcell: {
      background: 'linear-gradient(90deg, #ff7200 25%, #ecebeb 85%)',
      border: 0,
      borderRadius: 9,
      textAlign: 'left',
      paddingLeft: 10,
      '&:hover': {
        background: 'linear-gradient(90deg, #0cc988 25%, #ecebeb 85%)',
      },
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const abbreviationListItem: FC<PropsType> = (props: PropsType): ReactElement => {
  const karInfo = props.karInfo
  const classType = props.classType
  const { classes } = props

  return (
    <TableRow key={karInfo.id}>
      <td className={classType}>{karInfo.locatie}</td>
      <td className={classType}>{karInfo.karIdentificatie}</td>
      <td className={classType}>{karInfo.tijdstip}</td>
      <td className={classType}>{karInfo.richting}</td>
    </TableRow>
  )
}

export default withStyles(styles)(abbreviationListItem)
