import { Home, TextFormat, Message } from '@material-ui/icons'
import { RfhBottomNavigation } from '@rfh/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, withRouter } from 'react-router-dom'

export const BottomNavigation: FC = (): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleChange = (_: any, v: { id: string }) => {
    history.push(v.id === 'home' ? '/' : '/' + v.id)
  }

  const items = [
    {
      id: 'home',
      label: t('home'),
      icon: <Home />,
    },
    {
      id: 'lusinfo',
      label: t('lusinfo'),
      icon: <TextFormat />,
    },
  ]

  return (
    <RfhBottomNavigation
      navigationItems={items.map((v: any) => ({
        ...v,
        label: t(v.id),
      }))}
      onChange={handleChange}
    />
  )
}

export default withRouter(BottomNavigation)
