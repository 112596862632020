import { IConfig } from 'src/common/interfaces'
import { Config } from 'src/common/config'

const config: IConfig = Config
const hostname = window.location.hostname

export const getEnvConfig = (stage: string): IConfig => {
  if (
    process.env.REACT_APP_ENV === 'test' ||
    (process.env.NODE_ENV === 'production' && hostname === 'localhost')
  ) {
    // config = testConfig
    return config
  }

  if (hostname === 'localhost') {
    // config = devConfig
    return config
  }

  if (hostname === 'our.staging.url') {
    // config = stagingConfig
    return config
  }

  // config = prodConfig
  return config
}
