/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/unbound-method */
import { AddIcon, SearchIcon } from '@rfh/ui/shared/floriday-icons'
import {
  createStyles,
  Fab,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Select,
  Table,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core'
import { WithStyles } from '@material-ui/styles'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/nl'
import { useOktaAuth } from '@okta/okta-react'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Direction } from '../../common/enums'
import { IKarInfo, IKarInfoSelectie } from '../../common/interfaces'
import { createExclusiveFilter, IFilterArgument } from '../../common/utils/createFilter'
import { createSorter } from '../../common/utils/createSorter'
import KarInfoListItem from './components/KarInfoListItem'
import { ThemeConfig } from '../../common/config'
import SelectieButtonEnum from './components/SelectieButtonEnum'
import { vestigingen, VestigingEnum } from './components/VestigingEnum'
import { getKarInfos } from 'src/common/services/karInfoService'
import { TableRowMock } from '../abbreviations/views/abbreviation-list/components/TableRowMock'
type ClassKey =
  | 'cell'
  | 'fab'
  | 'root'
  | 'input'
  | 'table'
  | 'label'
  | 'th'
  | 'td'
  | 'container'
  | 'textField'
  | 'formControl'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {
      flexGrow: ThemeConfig.spacing.small,
    },
    cell: {
      textAlign: 'left',
    },
    fab: {
      position: 'fixed',
      bottom: `calc(${theme.spacing(ThemeConfig.spacing.small)}px + 86px)`, //TODO: RP: Temp fix voor appcontainer
      right: theme.spacing(ThemeConfig.spacing.small),
    },
    input: {
      margin: theme.spacing(ThemeConfig.spacing.small),
      height: '208px',
      minWidth: '200px',
    },
    table: {
      margin: theme.spacing(ThemeConfig.spacing.xsmall, ThemeConfig.spacing.small),
    },
    label: {
      textAlign: 'left',
      paddingRight: '5px',
    },
    th: {
      textAlign: 'left',
      alignContent: 'left',
    },
    td: {
      textAlign: 'left',
      alignContent: 'left',
      marginRight: '10px',
    },
    container: {},
    textField: {},
    formControl: {},
  })

const KarInfoList: FC<PropsType> = (props: PropsType): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const { authState } = useOktaAuth()
  const [karInfos, setKarInfos] = useState<IKarInfo[]>([])

  const [sortOrder] = useState<Direction>(Direction.asc)
  const [sorters, setSorters] = useState([
    {
      property: 'locatie',
      direction: sortOrder,
    },
  ])

  const defaultDatum = new Date()

  const [selectedWarehouse, setSelectedWarehouse] = useState('0')
  const [selectedDatum, setSelectedDatum] = useState(new Date().toLocaleString())
  const [selectedLdgIdf, setSelectedLdgIdf] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectieIncompleet, setSelectStatus] = useState(true)

  useEffect(() => {
    if (authState.isAuthenticated) {
      console.log(authState)
    }
  }, [authState])

  const fetchData = async (): Promise<void> => {
    try {
      if (
        selectedWarehouse !== '0' &&
        selectedDatum !== undefined &&
        (selectedLdgIdf.length > 0 || selectedLocation.length > 0)
      ) {
        console.log('zoek data')
        console.log(selectedWarehouse)
        console.log(selectedDatum)
        console.log(selectedLdgIdf)
        console.log(selectedLocation)
        const select: IKarInfoSelectie = {
          vestiging: selectedWarehouse,
          ladingdragerNummer: selectedLdgIdf,
          datum: selectedDatum.toLocaleString(),
          locatie: selectedLocation,
        }
        console.log({ select })
        const result = await getKarInfos(authState.accessToken?.accessToken, select)
        console.log('result:')
        console.log(result)
        console.log({ result })
        setKarInfos(result)
      } else {
        updateSelectieStatus()
        console.log('zoek data met niet volledige selectiecriteria aangeroepen')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const zoekIfEnter = (e: string) => {
    if (e === 'Enter') {
      fetchData()
    }
  }

  const updateSelectieStatus = () => {
    if (
      selectedWarehouse !== '0' &&
      selectedDatum !== undefined &&
      (selectedLdgIdf.length > 0 || selectedLocation.length > 0)
    ) {
      setSelectStatus(false)
    } else {
      setSelectStatus(true)
    }
  }

  const handleKarNummerChange = nummer => {
    setSelectedLdgIdf(nummer)
    updateSelectieStatus()
  }

  const handleVestigingChange = event => {
    const name = event.target.name
    setSelectedWarehouse(event.target.value)
    updateSelectieStatus()
  }

  const handleLocatieChange = locatie => {
    setSelectedLocation(locatie)
    updateSelectieStatus()
  }

  const handleDateChange = date => {
    setSelectedDatum(date.toLocaleString())
    updateSelectieStatus()
  }

  const submitHandler = e => {
    e.preventDefault()
  }

  const handleClose = (): void => {
    fetchData()
  }

  const { classes } = props

  return (
    <div className={classes.root}>
      <table className='selectie'>
        <thead></thead>
        <tbody>
          <tr onKeyUp={e => zoekIfEnter(e.key)}>
            <td>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor='age-native-label-placeholder'>
                  Vestiging
                </InputLabel>
                <Select
                  native
                  value={selectedWarehouse}
                  onChange={handleVestigingChange}
                  onSubmit={submitHandler}
                  inputProps={{
                    name: 'Vestiging',
                    id: 'age-native-label-placeholder',
                  }}
                >
                  <option aria-label='None' value='Geen' />
                  <option value={'1'}>Aalsmeer</option>
                  <option value={'2'}>Naaldwijk</option>
                  <option value={'3'}>Rijnsburg</option>
                </Select>
              </FormControl>
            </td>
            <td>
              <form className={classes.container} onSubmit={submitHandler} noValidate>
                <TextField
                  id='date'
                  label='Datum'
                  type='date'
                  defaultValue={defaultDatum.toLocaleString()}
                  onChange={e => handleDateChange(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </td>
            <td>
              <form
                className={classes.root}
                onSubmit={submitHandler}
                noValidate
                autoComplete='off'
              >
                <TextField
                  id='standard-number'
                  label='Karnummer'
                  type='number'
                  defaultValue={selectedLdgIdf}
                  onChange={e => handleKarNummerChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </td>
            <td>
              <form
                className={classes.root}
                onSubmit={submitHandler}
                noValidate
                autoComplete='off'
              >
                <TextField
                  id='standard-string'
                  label='Locatie'
                  type='string'
                  defaultValue={selectedLocation}
                  onChange={e => handleLocatieChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </td>
            <td>
              {!selectieIncompleet && (
                <button
                  className='btn btn-primary'
                  onClick={fetchData}
                  disabled={selectieIncompleet}
                >
                  Zoek data
                </button>
              )}
              {selectieIncompleet && <label>Voer selectiecriteria in...</label>}
            </td>
          </tr>
        </tbody>
      </table>

      <Table className={classes.table}>
        <thead>
          <tr>
            <th
              className={classes.cell}
              onClick={(): void =>
                setSorters([{ property: 'locatie', direction: Direction.asc }])
              }
            >
              {t('locatie')}
            </th>
            <th
              className={classes.cell}
              onClick={(): void =>
                setSorters([{ property: 'karIdentificatie', direction: Direction.asc }])
              }
            >
              {t('kar identificatie')}
            </th>
            <th
              className={classes.cell}
              onClick={(): void =>
                setSorters([{ property: 'tijdstip', direction: Direction.asc }])
              }
            >
              {t('tijdstip')}
            </th>
            <th
              className={classes.cell}
              onClick={(): void =>
                setSorters([{ property: 'richting', direction: Direction.asc }])
              }
            >
              {t('richting')}
            </th>
          </tr>
        </thead>
        <tbody>
          {!karInfos.length &&
            [...Array(20)].map(
              (_: unknown, index: number): ReactElement => <TableRowMock key={index} />
            )}
          {karInfos !== null &&
            karInfos.length &&
            Array.isArray(karInfos) &&
            karInfos?.map((karInfo: IKarInfo, index: number) => (
              <KarInfoListItem
                key={index}
                karInfo={karInfo}
                classType={classes.cell}
              ></KarInfoListItem>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default withStyles(styles)(KarInfoList)
