import { FC, ReactElement } from 'react'
import { DeleteIcon } from '@rfh/ui/shared/floriday-icons'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TableRow,
  IconButton,
} from '@material-ui/core'
import { IAbbreviation } from 'src/common/interfaces'

interface IProps {
  abbreviation: IAbbreviation
  classType: string
  removeItem: (
    id: string,
    text: string,
    text2: string,
    text3: string,
    text4: string
  ) => void
  gotoAbbreviation: (id: string) => void
}

type ClassKey = 'root' | 'abrcell'
const styles = (theme: Theme) =>
  createStyles<ClassKey, Record<string, unknown>>({
    root: {},
    abrcell: {
      background: 'linear-gradient(90deg, #ff7200 25%, #ecebeb 85%)',
      border: 0,
      borderRadius: 9,
      textAlign: 'left',
      paddingLeft: 10,
      '&:hover': {
        background: 'linear-gradient(90deg, #0cc988 25%, #ecebeb 85%)',
      },
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const abbreviationListItem: FC<PropsType> = (props: PropsType): ReactElement => {
  const abbreviation = props.abbreviation
  const classType = props.classType
  const { classes } = props

  return (
    <TableRow key={abbreviation.id}>
      <td
        className={classes.abrcell}
        onClick={(): void => props.gotoAbbreviation(abbreviation.id)}
      >
        {abbreviation.abbreviation}
      </td>
      <td className={classType}>{abbreviation.description}</td>
      <td className={classType}>{abbreviation.englishDescription}</td>
      <td className={classType}>{abbreviation.description2}</td>
      <td className={classType}>{abbreviation.created}</td>
      <td className={classType}>{abbreviation.createdBy}</td>
      <td>
        <IconButton
          aria-label='delete'
          color='primary'
          onClick={(): void =>
            props.removeItem(
              abbreviation.id,
              'deleteConfirm',
              'cannotUndoWarning',
              'ok',
              'cancel'
            )
          }
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </TableRow>
  )
}

export default withStyles(styles)(abbreviationListItem)
