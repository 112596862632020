import { Direction } from '../enums'

interface IOrder {
  descending: number
  ascending: number
}

interface IDirectionMap {
  greaterThan: IOrder
  lessThan: IOrder
}

interface ISortArgument {
  property: string
  direction?: Direction
}

const dirMap: IDirectionMap = {
  greaterThan: { ascending: 1, descending: -1 },
  lessThan: { ascending: -1, descending: 1 },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const doSort = <T>(
  A: T,
  B: T,
  property: string,
  direction: Direction = Direction.asc
): number => {
  const a = (A as any)[property]
  const b = (B as any)[property]

  if (a < b) {
    return direction === Direction.asc
      ? dirMap.lessThan.ascending
      : dirMap.lessThan.descending
  }
  if (a > b) {
    return direction === Direction.asc
      ? dirMap.greaterThan.ascending
      : dirMap.greaterThan.descending
  }
  return 0
}

type curryType = <T>(A: T, B: T) => number
// TODO AR: discuss the return value with Sander
// TODO AR: maybe use a function inside sort?
/**
 * A sort function which sorts the items based on the `sorters` provided.
 * @param sorters An array of sorters based on `ISortArgument`
 */
const createSorter =
  (...sorters: ISortArgument[]): curryType =>
  <T>(A: T, B: T): number => {
    let returnValue = 0

    // Needs work (?)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sortResult = sorters.some((sorter: ISortArgument): boolean => {
      const { property, direction } = sorter
      returnValue = doSort(A, B, property, direction)
      return returnValue !== 0
    })

    return returnValue
  }

export { createSorter }
