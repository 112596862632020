import { Container, makeStyles, Theme } from '@material-ui/core'
import { RfhTheme } from '@rfh/ui'
import { FC, ReactChildren } from 'react'
import { AppBar } from './AppBar'
import { BottomNavigation } from './BottomNavigation'
import { ThemeConfig } from 'src/common/config'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(ThemeConfig.spacing.smallmedium),
    paddingBottom: theme.spacing(ThemeConfig.spacing.xlarge),
  },
}))

export const Layout: FC = props => {
  const styles = useStyles()

  return (
    <RfhTheme>
      <AppBar />
      <Container maxWidth='lg' classes={styles}>
        {props.children as ReactChildren}
      </Container>
      <BottomNavigation />
    </RfhTheme>
  )
}
